.mat-dialog-container {
  border-radius: 20px !important;
}

.mat-toolbar {
  border-radius: 10px !important;
  background-color: rgba(248, 243, 233, 0.51);
}


